import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.concat.js";
/* eslint-disable unicorn/filename-case */
var protocol = window.location.protocol === "https:" ? "wss:" : "ws:";
// Add trailing slash to path, if necessary, before appending "autoreload"
var defaultPath = window.location.pathname.replace(/\/?$/, "/") + "autoreload/";
var defaultUrl = "".concat(protocol, "//").concat(window.location.host).concat(defaultPath);

// By default, use the defaultUrl. But if there's a data-ws-url attribute on our
// <script> tag, use that instead.
var wsUrl = document.currentScript.dataset.wsUrl || defaultUrl;
var ws = new WebSocket(wsUrl);
ws.onmessage = function (event) {
  if (event.data === "autoreload") {
    window.location.reload();
  }
};
export {};